<template>
  <b-container class="bg-blue border-0 fontStandard" :style="this['events/handleMinHeight']">
    <h1 class="text-center mt-5">
      <i class="big_text fas fa-unlock-alt pb-1" />
      <br />

      <b-badge pill variant="light">
        <strong class="fontStandard text-primary">
          <BTextUppercase>Mot de passe oublié</BTextUppercase>
        </strong>
      </b-badge>
    </h1>
    <br />

    <b-form class="mx-auto max_width_400">
      <b-form-group>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-envelope"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            id="content_email"
            class="form-control"
            placeholder="Indiquez votre adresse email"
            autocomplete="off"
            type="email"
            v-model="recover_email"
            :state="check_mail(recover_email)"
          />
          <b-popover
            v-if="!check_mail(recover_email)"
            target="content_email"
            placement="topleft"
            title="Requis"
            variant="danger"
            triggers="hover focus"
            content="Une adresse email est requise pour une demande pour mot de passe perdu ou oublié"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group v-if="account.axios_data && account.axios_data.picture">
        <b-input-group>
          <b-img
            width="500"
            fluid
            thumbnail
            center
            rounded="top"
            block
            :src="account.axios_data.picture"
            alt="captcha"
          />
          <b-input-group-prepend>
            <b-button @click="renewCaptcha()" variant="primary">
              <i class="fas fa-undo-alt" />
            </b-button>
          </b-input-group-prepend>
          <b-input
            class="form-control"
            placeholder="Captcha"
            type="text"
            v-model="captcha"
            @click="captcha = ''"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-button type="submit" variant="primary" @click="recover" block>
          <i class="fas fa-unlock-alt" /> Envoyer le mail de récuperation
        </b-button>
      </b-form-group>

      <p class="text-center">
        Vous avez un compte?
        <b-link tag="a" to="login">Connexion</b-link>
      </p>
    </b-form>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Form from "@/mixins/Form";

export default {
  components: { BTextUppercase: () => import("@/components/BTextUppercase") },
  mixins: [Form],
  data() {
    return {
      recover_email: "",
    };
  },
  computed: {
    ...mapState(["account"]),
    ...mapGetters(["events/handleMinHeight"]),
  },
  mounted() {
    this.$store.dispatch("axios/http_reading", true);
    // this.$root.axios("get", "api/captcha/ask");
    this.$root.handleGetRequest("api/captcha/ask", true);
  },
  beforeDestroy() {
    this.$root.renewToken();
    this.recover_email = this.captcha = undefined;
  },
  methods: {
    async recover(e) {
      e.preventDefault();

      await this.$store.dispatch("account/lostpassword", {
        vm: this,
        recover_email: this.recover_email,
        captcha: this.captcha,
      });
    },
  },
};
</script>
